<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
      v-loading="!nodeLoading"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input v-model="nodeToBind.node_name"></el-input>
      </el-form-item>

      <el-form-item
        prop="text_form_node.data.input_text"
        class="is-required"
        :label="__('Query')"
      >
        <input-variable-popper
          v-model="nodeToBind.text_form_node.data.input_text"
          :is-text-area="false"
          :placeholder="__('Enter your text')"
          :include-secure-variables="false"
        />
      </el-form-item>

      <el-tabs v-model="activeTab" class="tabs" style="font-size: 1.2em;">
        <el-tab-pane :label="__('Form')" name="form">
          <el-form-item
            prop="text_form_node.data.form_type_id"
            :label="__('Open Form')"
            :required="true"
          >
            <el-select
              v-model="form_type_obj_temp"
              style="width: 100%;"
              @change="formTypeIdSelection($event)"
              :placeholder="__('Select open form')"
              value-key="id"
              default-first-option
              filterable
              ref="formTypeElSelect"
            >
              <el-option-group
                v-for="(open_form_type, drop_down_label) in groupedFormType"
                :key="drop_down_label"
                :label="drop_down_label"
              >
                <el-option
                  v-for="(item, index) in open_form_type"
                  :label="item.oft_name"
                  :value="item"
                  :key="index"
                >
                  <span style="float: left; font-size: 13px; color: #181F29;">{{
                    item.oft_name
                  }}</span>
                  <span
                    style="float: right; color: #8492a6; font-size: 13px;  text-transform: capitalize;"
                    >{{ item.nlp_engine_label }}</span
                  >
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="text_form_node.data.provider"
            :label="__('Provider')"
            v-if="showProviderField"
          >
            <el-radio-group
              v-model="nodeToBind.text_form_node.data.provider"
              @change="handleChangeProvider"
            >
              <el-radio-button label="lumenvox">Lumenvox</el-radio-button>
              <el-radio-button label="google">Google</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            prop="text_form_node.data.form_language_id"
            :label="__('Language')"
            v-if="showLanguageField"
          >
            <el-select
              v-model="nodeToBind.text_form_node.data.form_language_id"
              style="width: 100%;"
              :placeholder="__('Select language')"
              default-first-option
              filterable
              clearable
              :loading-text="__('fetching languages')"
              :no-data-text="noText"
            >
              <el-option
                v-for="(item, index) in filteredLanguages"
                :label="item.language_name"
                :value="item.language_id"
                :key="index"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            prop="text_form_node.data.form_timezone"
            :label="__('Timezone')"
            v-if="isNlpLex"
          >
            <timezone-dropdown
              :default_timezone="nodeToBind.text_form_node.data.form_timezone"
              @timezone-changed="handleTimezoneChange"
            />
          </el-form-item>

          <el-form-item
            prop="text_form_node.data.variable_rules"
            :label="__('Return Values')"
            v-if="showReturnValuesTable && IsReturnValuesEmpty"
          >
            <el-table
              :data="getVariableRules"
              max-height="250"
              style="width: 100%"
            >
              <el-table-column prop="return_value_name" :label="__('Value')">
              </el-table-column>
              <el-table-column
                prop="variable_id"
                :label="__('Assign the value to variable')"
              >
                <template slot-scope="scope">
                  <create-or-select
                    :items="singleValuedAndSecureVariables"
                    label="variable_name"
                    value="variable_id"
                    :current_select="currentSelection(scope.row)"
                    :placeholder="__('Variable name (optional)')"
                    :new-item-message="__('new variable')"
                    @change="handleChange($event)(scope.row)"
                  />
                </template>
              </el-table-column>
              <el-table-column
                :label="__('Default Value')"
                prop="default_value"
              >
                <template slot-scope="scope">
                  <input-variable-popper
                    v-model="scope.row.default_value"
                    :is-text-area="false"
                    force-reinitialize
                    :placeholder="__('Default Value')"
                    :include-secure-variables="false"
                  ></input-variable-popper>
                </template>
              </el-table-column>
              <el-table-column
                class-name="row-message"
                width="100px"
                prop="msg"
              />
            </el-table>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane :label="__('NLP Settings')" name="advanced_settings">
          <el-form-item
            prop="text_form_node.data.query_parameters"
            :label="__('Query Parameters')"
            v-if="showTestQuery"
          >
            <div class="phraseHintEditor">
              <input-variable-popper
                :value="queryParameters"
                is-text-area
                :ats="['{{']"
                popper-class="prompt-node-popper"
                @input="updateQueryParameters($event)"
                :include-secure-variables="false"
              />
            </div>
            <div class="warning-info" v-if="showWarning">
              <div class="warning-exclamation" />
              <div style="margin-left: 5px; display: flex">
                <div>
                  {{ __("Ensure that variable has a valid JSON value") }}
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            prop="text_form_node.data.language_variable_id"
            :label="__('Language Variable')"
            v-if="!isNlpWatson"
          >
            <language-variable
              v-model="languageVariable"
              @lang-var-changed="handleLanguageVarChanged"
            />
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import { NODE_TYPES } from "@/constants/nodes";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import CreateOrSelect from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelect";
import { phraseHintsRegex } from "@/utils/regex";
import TimezoneDropdown from "@/components/TimezoneDropdown.vue";
import { filterRowsIfSomeKeyValueIsAbsent } from "@/utils/collection";
import LanguageVariable from "@/views/build/callflow/components/node-type-forms/components/LanguageVariable";

import _ from "lodash";
import { mapActions, mapState } from "vuex";
import { EventBus } from "@/EventBus";
import {
  NLP_DIALOGFLOW,
  NLP_DIALOGFLOW_CX,
  NLP_LEX,
  NLP_WATSON
} from "@/constants/openFormType";

export default {
  components: {
    InputVariablePopper,
    TimezoneDropdown,
    CreateOrSelect,
    LanguageVariable
  },
  mixins: [BaseNode],
  data() {
    let validateQueryParameters = async (rule, value, cb) => {
      this.showWarning = false;
      if (this.hasInvalidVariable(value)) {
        cb(__("Invalid variables found in Query Params"));
      } else {
        cb();
      }
    };
    return {
      form_type_model: "Open Form",
      activeTab: "form",
      allowedRegex: phraseHintsRegex,
      form_type_obj_temp: {},
      preText: "",
      variableRulesBkup: [],
      selectedReturnValues: [],
      transcribeTextObj: {},
      transcribeTextRuleValue: "sys.open_form_transcribed_text_var",
      textFormNodeResponse: {
        ws_response_data: {
          raw_transcribe: "",
          confidence_score: "",
          additional_information: []
        },
        ws_response_meta: []
      },
      showWarning: false,
      regExForVariable: new RegExp("^{{.*}}$"),
      tabStructure: {
        form: [
          "text_form_node.data.form_type_id",
          "text_form_node.data.form_language_id"
        ],
        advanced_settings: [
          "text_form_node.data.language_variable_id",
          "text_form_node.data.query_parameters"
        ]
      },
      rules: {
        "text_form_node.data.form_type_id": [
          {
            required: true,
            message: __("Please select an open form"),
            trigger: "blur"
          }
        ],
        "text_form_node.data.form_language_id": [
          {
            required: true,
            message: __("Please select a language"),
            trigger: "blur"
          }
        ],
        "text_form_node.data.input_text": [
          {
            required: true,
            message: __("Please enter the query input text"),
            trigger: "blur"
          }
        ],
        "text_form_node.data.query_parameters": [
          { validator: validateQueryParameters, trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    ...mapActions("openFormTypes", {
      getOpenFormTypes: "getOpenFormTypes",
      resetOpenFormTypes: "resetOpenFormTypes"
    }),
    ...mapActions("formTypes", {
      getFormTypesWithDatastoreColumns: "getFormTypesWithDatastoreColumns",
      resetFormTypes: "resetFormTypes"
    }),
    ...mapActions("dataStores", {
      getDataStores: "getDataStores",
      resetDataStores: "resetDataStores"
    }),
    ...mapActions("asrLanguages", {
      getASRLanguages: "getASRLanguages"
    }),
    updateQueryParameters(value) {
      this.$set(this.nodeToBind.text_form_node.data, "query_parameters", value);
    },

    async handleTimezoneChange(timezone) {
      this.nodeToBind.text_form_node.data.form_timezone = timezone;
    },
    formTypeIdSelection(row) {
      let selectedFormTypeId;
      if (row.type === "Custom Form") {
        selectedFormTypeId = row.form_type_id;
        this.resetFormParameters();
        this.$set(this.nodeToBind.text_form_node.data, "provider", "lumenvox");
      } else {
        selectedFormTypeId = row.open_form_type_id;
        this.$set(this.nodeToBind.text_form_node.data, "provider", "");

        if (row.nlp_engine === "lex") {
          this.nodeToBind.text_form_node.data.query_parameters = "";
        }
      }
      this.nodeToBind.text_form_node.data.form_type_id = selectedFormTypeId;
      this.nodeToBind.text_form_node.data.form_type_model = row.type;

      this.setReturnValues(selectedFormTypeId, row.type);
      this.$set(this.nodeToBind.text_form_node.data, "variable_rules", {});
      this.$set(this.nodeToBind.text_form_node.data.variable_rules, "data", []);
      let vm = this;
      _.forEach(this.selectedReturnValues, function(value) {
        vm.nodeToBind.text_form_node.data.variable_rules.data.push({
          return_value_name: value.output_name,
          rule_value: value.output_id,
          variable_id: "",
          variable_name: "",
          default_value: "",
          msg: ""
        });
      });
    },
    getReturnValueName(rule, type) {
      if (
        !_.startsWith(rule.rule_value, "sys.") &&
        rule.variable_type !== "array"
      )
        if (type === "Open Form") {
          let outputIdInt = parseInt(rule.rule_value);
          let mapReturnValName = _.find(this.selectedReturnValues, [
            "output_id",
            outputIdInt
          ]);
          return mapReturnValName.output_name;
        }
      return "";
    },
    handleChange(option) {
      return row => {
        this.$nextTick(() => {
          if (
            !_.isEmpty(this.selectedReturnValues) &&
            row.variable_type !== "array"
          ) {
            let type = this.nodeToBind.text_form_node.data.form_type_model;
            let returnValueName;
            if (type === "Open Form") {
              let outputIdInt = parseInt(row.rule_value);
              let mapReturnValName = _.find(this.selectedReturnValues, [
                "output_id",
                outputIdInt
              ]);
              returnValueName = mapReturnValName.output_name;
            }
            this.$set(row, "return_value_name", returnValueName);
            this.$set(row, "variable_name", option.label);
            this.$set(row, "variable_id", option.value);
            this.$set(row, "msg", option.msg);
          }
        });
      };
    },
    setReturnValues(form_type_id, type) {
      if (form_type_id > 0 && !_.isEmpty(type)) {
        let formTypesCloned;
        let returnVal;
        if (type === "Custom Form") {
          formTypesCloned = _.cloneDeep(this.formTypes);
          let filterFormTypes = _.filter(formTypesCloned, {
            form_type_id: form_type_id
          });
          if (!_.isEmpty(filterFormTypes[0].collection_structure)) {
            returnVal = filterFormTypes[0].collection_structure;
            returnVal = returnVal.map(v => {
              return Object.assign(v, {
                output_id: v.col_name,
                output_name: v.col_name
              });
            });
          }
        } else {
          formTypesCloned = _.cloneDeep(this.openFormTypes);
          let filterOpenFormTypes = _.filter(formTypesCloned, {
            open_form_type_id: form_type_id
          });
          returnVal = filterOpenFormTypes[0].oft_outputs;
        }
        this.selectedReturnValues = returnVal;
      }
    },
    resetFormParameters() {
      this.nodeToBind.text_form_node.data.query_parameters = "";
    },

    maskOpenFormTypesData(openFormTypesData) {
      return openFormTypesData.map(v => ({
        ...v,
        type: "Open Form",
        id: _.toString(v.open_form_type_id).concat("_openform")
      }));
    },
    getFormTypeObj(form_type_id, form_type_model) {
      if (form_type_model === "Open Form") {
        const openFormType = _.find(this.openFormTypes, {
          open_form_type_id: form_type_id
        });
        return this.maskOpenFormTypesData([openFormType]);
      } else if (form_type_model === "Custom Form") {
        const customFormType = _.find(this.formTypes, {
          form_type_id: form_type_id
        });

        return this.maskCustomFormTypesData([customFormType]);
      }
    },
    async initializeLanguages() {
      await this.getASRLanguages({ fetch_all: 0 });
    },

    async checkASRLanguagesLength() {
      if (!this.asrLanguages.length && !this.asrLanguagesLoading) {
        this.$message({
          type: "error",
          // eslint-disable-next-line
          message: __("ASR languages are not provisioned in your business plan")
        });
      }
    },

    async initializeOpenFormTypes() {
      this.resetOpenFormTypes();
      this.resetFormTypes();
      let apis = [
        this.getFormTypesWithDatastoreColumns({
          is_archived: "all",
          fetch_all: 1
        }),
        this.getOpenFormTypes({ is_archived: "all", fetch_all: 1 })
      ];
      // if (this.accountRawNodes && this.accountRawNodes[0]) {
      //   let selectedAcNodes = _.find(this.accountRawNodes, {
      //     ac_id: this.selectedAccountId
      //   });
      //   if (selectedAcNodes && selectedAcNodes.voice) {
      //     if (
      //       selectedAcNodes.voice &&
      //       selectedAcNodes.voice.includes("open_form")
      //     ) {
      //       apis.push(
      //         this.getOpenFormTypes({ is_archived: "all", fetch_all: 1 })
      //       );
      //     }
      //   }
      // }
      await Promise.all(apis);
    },
    initializeReturnValues() {
      let results;
      let type;
      if (this.nodeToBind.text_form_node.data.form_type_id > 0) {
        let form_type_id = this.nodeToBind.text_form_node.data.form_type_id;
        let form_type_model = this.nodeToBind.text_form_node.data
          .form_type_model;
        this.setReturnValues(form_type_id, form_type_model);

        let maskedFormTypeDataObj = this.getFormTypeObj(
          form_type_id,
          form_type_model
        );
        this.form_type_obj_temp = maskedFormTypeDataObj[0];
      }

      if (
        this.nodeToBind.text_form_node.data.form_type_id > 0 &&
        !_.isEmpty(this.nodeToBind.text_form_node.data.variable_rules.data)
      ) {
        const assignedVariableRuleData = _.remove(
          this.nodeToBind.text_form_node.data.variable_rules.data,
          function(rule) {
            return rule.rule_value !== "root";
          }
        );

        this.nodeToBind.text_form_node.data.variable_rules.data = assignedVariableRuleData;

        results = this.selectedReturnValues.filter(
          ({ output_id: id1 }) =>
            !assignedVariableRuleData.some(({ rule_value: id2 }) => {
              type = this.nodeToBind.text_form_node.data.form_type_model;
              let parseId2 = type === "Open Form" ? parseInt(id2) : id2;
              return parseId2 === id1;
            })
        );
      } else {
        results = this.selectedReturnValues;
      }

      let vm = this;
      _.forEach(results, function(value) {
        vm.nodeToBind.text_form_node.data.variable_rules.data.push({
          return_value_name: value.output_name,
          rule_value: value.output_id,
          variable_id: "",
          variable_name: "",
          default_value: "",
          msg: ""
        });
      });
    },
    removeArchivedFormTypes(formTypes) {
      let selectedFormTypeRefId = _.get(this.form_type_obj_temp, "id");
      return _.reject(formTypes, function(o) {
        return o.id === selectedFormTypeRefId ? false : o.is_archived;
      });
    },
    handleChangeProvider() {
      this.resetLanguage();
    },
    resetLanguage() {
      this.nodeToBind.text_form_node.data.form_language_id = null;
    },
    cleanUpNodeToPrepareForSubmit() {
      if (this.isNlpWatson) {
        const defaultLanguageId = _.get(
          this.asrLanguages,
          "[0].language_id",
          null
        );
        this.nodeToBind.text_form_node.data.form_language_id = _.get(
          this.filteredLanguages,
          "[0].language_id",
          defaultLanguageId
        );
      }

      return _.cloneDeep(this.nodeToBind);
    },
    setDefaultSystemVariableRules() {
      if (!_.isEmpty(this.transcribeTextObj)) {
        this.removeVariableRule(this.transcribeTextRuleValue);
        this.nodeToBind.text_form_node.data.variable_rules.data.push(
          this.transcribeTextObj
        );
      }
    },
    cleanUpFormTypeData(formType) {
      if (this.checkNlpEngineType === "builtin") {
        this.$set(this.nodeToBind.text_form_node.data, "provider", "lumenvox");
        this.removeAllSystemDefaultReturnValues();
      } else if (formType === "Open Form") {
        this.$set(this.nodeToBind.text_form_node.data, "provider", "google");
        if (this.checkNlpEngineType === "lex") {
          this.$set(
            this.nodeToBind.text_form_node.data,
            "query_parameters",
            ""
          );
        }
      }
    },
    setTextFormNodeJsonResponse() {
      let rule = this.arrayRule();
      rule.default_value = JSON.stringify(this.textFormNodeResponse);
    },
    arrayRule() {
      let arrayRule = _.find(
        this.getVariableRules,
        rule => rule.variable_name === this.arrayVariableName
      );
      let { variable_name, variable_id } = this.arrayVariable;
      if (!arrayRule) {
        arrayRule = {
          rule_value: "root",
          default_value: "",
          variable_name,
          variable_id,
          array_variable: true,
          variable_type: "array"
        };
        this.nodeToBind.text_form_node.data.variable_rules.data.push(arrayRule);
      }
      return arrayRule;
    },
    cleanUpNode() {
      this.setDefaultSystemVariableRules();
      this.variableRulesBkup = _.cloneDeep(
        this.nodeToBind.text_form_node.data.variable_rules.data
      );
      this.setDefaultSystemVariableRules();
      this.cleanUpFormTypeData(
        this.nodeToBind.text_form_node.data.form_type_model
      );

      let filteredVariableRulesData = filterRowsIfSomeKeyValueIsAbsent(
        this.nodeToBind.text_form_node.data.variable_rules.data,
        "variable_name"
      );
      this.$set(this.nodeToBind.text_form_node.data.variable_rules, "data", []);
      this.nodeToBind.text_form_node.data.variable_rules.data = filteredVariableRulesData;

      this.setTextFormNodeJsonResponse();
      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.createOrEditNode();
    },
    createOrEditNode() {
      if (!this.isTaskReadOnly) {
        this.attemptedToSubmit = true;
        this.$refs.nodeForm.validate((valid, errors) => {
          if (valid) {
            this.process({
              node: this.nodeToBind,
              nodeInContext: this.node
            })
              .then(async () => {
                this.newVariableCreated
                  ? await this.forceFetchVariables()
                  : null;
                this.setClickedNode(null);
                this.toggleNodeSubmit(false);
              })
              .catch(() => {
                this.toggleNodeSubmit(false);
              });
          } else {
            let errMessages = _.flatten(
              _.map(errors, err => {
                return _.map(err, "message");
              })
            );

            _.map(errMessages, message => {
              setTimeout(() => this.errorNotification(message), 100);
            });
            this.toggleNodeSubmit(false);
            this.nodeToBind.text_form_node.data.variable_rules.data = _.cloneDeep(
              this.variableRulesBkup
            );

            let errorPropName = Object.keys(errors);
            let findTab = _.findKey(this.tabStructure, function(structure) {
              return structure.some(r => errorPropName.includes(r));
            });

            if (!_.isEmpty(findTab)) {
              this.activeTab = findTab;
            }
            return false;
          }
        });
      }
    },
    handleLanguageVarChanged(langVarId) {
      this.languageVariable = langVarId;
    }
  },
  computed: {
    ...mapState("openFormTypes", {
      openFormTypes: state => state.openformtypes,
      openFormTypesLoading: state => state.loading
    }),
    ...mapState("formTypes", {
      formTypes: state => state.formtypes,
      formTypesLoading: state => state.loading
    }),
    ...mapState("dataStores", {
      dataStores: state => state.dataStores,
      dataStoresLoading: state => state.loading
    }),
    ...mapState("asrLanguages", {
      asrLanguages: state => state.asrLanguages,
      asrLanguagesLoading: state => state.loading
    }),
    ...mapState("nodeTypes", {
      accountRawNodes: state => state.accountRawNodes
    }),
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),
    nodeLoading() {
      return (
        !this.openFormTypesLoading &&
        !this.asrLanguagesLoading &&
        !this.formTypesLoading
      );
    },
    currentSelection() {
      return row => {
        const { variable_id, variable_name } = row;
        return variable_id === -1 ? variable_name : variable_id;
      };
    },
    filteredLanguages() {
      let asrLanguages = [];
      let self = this;
      if (this.isNlpLex) {
        asrLanguages = _.filter(this.asrLanguages, [
          "language_provider",
          "amazon"
        ]);
        self.preText = "Amazon Lex";
      } else if (this.isNlpDialogflow) {
        asrLanguages = _.filter(this.asrLanguages, function(o) {
          return (
            o.language_provider === "google" &&
            o.language_used_in.includes("dialogflow")
          );
        });
        self.preText = "Google dialogflow";
      } else if (this.isNlpDialogflowCx) {
        asrLanguages = _.filter(this.asrLanguages, function(o) {
          return (
            o.language_provider === "google" &&
            o.language_used_in.includes("df_cx")
          );
        });
        self.preText = "Google Dialogflow CX";
      } else if (
        this.currentProvider === "lumenvox" &&
        this.checkNlpEngineType === "builtin" &&
        this.getFormTypeName !== "Integer"
      ) {
        asrLanguages = _.filter(this.asrLanguages, [
          "language_provider",
          "lumenvox"
        ]);
        self.preText = "Lumenvox";
      } else if (
        this.checkNlpEngineType === "builtin" &&
        this.getFormTypeName === "Integer"
      ) {
        asrLanguages = _.filter(this.asrLanguages, lang => {
          console.log(lang.language_name);
          return (
            lang.language_provider === "lumenvox" &&
            lang.language_name.includes("English")
          );
        });
      } else if (this.isNlpWatson) {
        asrLanguages = _.filter(this.asrLanguages, [
          "language_provider",
          "ibm_watson"
        ]);
        self.preText = "IBM Watson";
      } else {
        asrLanguages = _.filter(this.asrLanguages, function(o) {
          return (
            o.language_provider === "google" &&
            o.language_used_in.includes("cloud_speech")
          );
        });
        self.preText = "Google Cloud Speech to Text";
      }
      return asrLanguages;
    },
    showProviderField() {
      return this.showReturnValuesTable && this.checkNlpEngineType === "custom";
    },
    showReturnValuesTable() {
      return !_.isEmpty(this.form_type_obj_temp);
    },
    showLanguageField() {
      return (
        this.showReturnValuesTable &&
        !this.isNlpWatson &&
        !this.isNlpAzureOpenAi &&
        !this.isNlpOpenAi
      );
    },
    showTestQuery() {
      return this.isNlpDialogflow || this.isNlpWatson || this.isNlpDialogflowCx;
    },
    IsReturnValuesEmpty: function() {
      return !_.isEmpty(this.getVariableRules);
    },
    getVariableRules: function() {
      return _.filter(
        this.isNodeSubmit
          ? this.variableRulesBkup
          : this.nodeToBind.text_form_node.data.variable_rules.data,
        function(v) {
          return (
            !_.startsWith(v.rule_value, "sys.") && v.variable_type !== "array"
          );
        }
      );
    },
    noText() {
      // eslint-disable-next-line
      return __(":preText ASR languages are not provisioned in your business plan", {
          preText: this.preText
        }
      );
    },
    checkNlpEngineType() {
      let selectedFormType = this.form_type_obj_temp;
      if (_.has(selectedFormType, "form_type_id")) {
        if (_.get(selectedFormType, "is_standard")) {
          return "builtin";
        }
        return "custom";
      }
      if (_.isEmpty(selectedFormType)) {
        return "custom";
      }
      return selectedFormType.nlp_engine;
    },
    queryParameters() {
      return this.nodeToBind.text_form_node.data.query_parameters;
    },
    groupedFormType() {
      let groupOpenForm = {};
      if (!_.isEmpty(this.openFormTypes)) {
        let openFormTypes = this.openFormTypes.map(v => ({
          ...v,
          type: "Open Form",
          drop_down_label: __("Open Form"),
          group: v.is_system ? __("System Open Form") : __("Open Form"),
          id: _.toString(v.open_form_type_id).concat("_openform"),
          nlp_engine_label: v.nlp_engine.includes("dialogflow")
            ? v.nlp_engine === "dialogflow"
              ? "Dialogflow ES"
              : "Dialogflow CX"
            : v.nlp_engine
        }));
        openFormTypes = this.removeArchivedFormTypes(openFormTypes);
        groupOpenForm = _.mapValues(_.groupBy(openFormTypes, "group"));
      }

      return groupOpenForm;
    },
    languageVariable: {
      get: function() {
        return this.nodeToBind.text_form_node.data.language_variable_id || -1;
      },
      set: function(val) {
        this.$set(
          this.nodeToBind.text_form_node.data,
          "language_variable_id",
          val
        );
      }
    },
    isNlpDialogflow() {
      return this.checkNlpEngineType === NLP_DIALOGFLOW;
    },
    isNlpDialogflowCx() {
      return this.checkNlpEngineType === NLP_DIALOGFLOW_CX;
    },
    isNlpLex() {
      return this.checkNlpEngineType === NLP_LEX;
    },
    isNlpWatson() {
      return this.checkNlpEngineType === NLP_WATSON;
    }
  },
  async created() {
    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.text_form_node)) {
      this.$set(this.nodeToBind, "node_type", NODE_TYPES.TEXT_FORM.NODE_TYPE);
      this.$set(this.nodeToBind, "text_form_node", {});
      this.$set(this.nodeToBind.text_form_node, "data", {});

      this.$set(
        this.nodeToBind.text_form_node.data,
        "language_variable_id",
        null
      );
      this.$set(this.nodeToBind.text_form_node.data, "form_type_model", "");
      this.$set(this.nodeToBind.text_form_node.data, "form_type_id", null);
      this.$set(this.nodeToBind.text_form_node.data, "form_language_id", null);
      this.$set(this.nodeToBind.text_form_node.data, "form_timezone", "");
      this.$set(this.nodeToBind.text_form_node.data, "provider", "");
      this.$set(this.nodeToBind.text_form_node.data, "variable_rules", {});
      this.$set(this.nodeToBind.text_form_node.data.variable_rules, "data", []);
      this.$set(this.nodeToBind.text_form_node.data, "input_text", "");
      this.$set(this.nodeToBind.text_form_node.data, "query_parameters", "");
    }
    await this.initializeOpenFormTypes();
    await this.initializeLanguages();
    await this.checkASRLanguagesLength();
    this.initializeReturnValues();
    EventBus.$on("fetch-form-type", this.initializeOpenFormTypes);
  },
  watch: {
    variableRulesBkup: {
      deep: true,
      handler(val) {
        this.newVariableCreated = _.some(
          val,
          variable => variable.variable_id === -1
        );
      }
    },
    selectedReturnValues: {
      deep: true,
      handler() {
        let variableRules = _.cloneDeep(
          this.nodeToBind.text_form_node.data.variable_rules.data
        );
        let type = this.nodeToBind.text_form_node.data.form_type_model;
        this.nodeToBind.text_form_node.data.variable_rules.data = _.map(
          variableRules,
          rule => {
            return {
              ...rule,
              return_value_name: this.getReturnValueName(rule, type)
            };
          }
        );
      }
    },
    form_type_obj_temp: {
      deep: true,
      handler() {
        if (
          this.nodeToBind.text_form_node.data.form_language_id > 0 &&
          !_.isEmpty(this.filteredLanguages)
        ) {
          let acceptableLanguage = _.find(this.filteredLanguages, [
            "language_id",
            this.nodeToBind.text_form_node.data.form_language_id
          ]);
          if (_.isEmpty(acceptableLanguage)) {
            this.$set(
              this.nodeToBind.text_form_node.data,
              "form_language_id",
              null
            );
          }
        }
      }
    }
  },
  beforeDestroy() {
    EventBus.$off("fetch-form-type");
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
@import "~@/styles/node_common.scss";

::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.phraseHintEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-radius: 6px;
    border: 1px solid #a0a8b5;
    /*<!--box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),-->*/
    /*<!--  0 3px 3px -2px rgba(0, 0, 0, 0.12);-->*/
    margin-left: 5px;
    outline: none;
    padding: 10px;
    overflow: auto;
  }
}

.warning-info {
  color: #696969;
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin-top: 5px;
  font-size: 0.8125rem;
  letter-spacing: 0.005rem;
}
</style>
